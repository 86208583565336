<template>
    <div class="side-bar-components">
        <div class="side-bar-toggle show-button" @click="toggleSideBar(true)">
            <img src="@assets/img/icons/hamburger-menu-2.svg">
        </div>
        <div
            v-intro-autostart="showSideBarTour"
            v-intro-autostart.config="{ showStepNumbers: false, prevLabel: 'Previous', nextLabel: 'Next', hidePrev: true, hideNext: true, overlayOpacity: 0 }"
            v-intro-autostart:on.complete="setTourEnded"
            v-intro-autostart:on.exit="setTourEnded"
            class="side-bar"
            :class="{ 'show-side-bar' : showSideBar }"
        >
            <div class="top">
                <div class="side-bar-header">
                    <div class="side-bar-toggle hide-button" @click="toggleSideBar(false)">
                        <img src="@assets/img/icons/x.svg" width="15px">
                    </div>
                    <div
                        v-intro-position="'right'"
                        v-intro="introTexts.search"
                        v-intro-tooltip-class="`sidebar`"
                        class="search-input"
                    >
                        <img class="search-icon" src="@assets/img/icons/search.svg">
                        <input
                            type="text"
                            placeholder="Find Memos..."
                            @keyup.enter="(event) => search(event.target.value)"
                        >
                    </div>
                </div>
                <ul
                    class="nav-links"
                >
                    <li
                        v-intro-position="'right'"
                        v-intro="introTexts.explore"
                        v-intro-tooltip-class="`sidebar`"
                        class="nav-link"
                    >
                        <router-link :to="{ name: 'landing' }">
                            Explore
                        </router-link>
                    </li>
                    <li
                        v-intro-position="'right'"
                        v-intro="introTexts.about"
                        v-intro-tooltip-class="`sidebar`"
                        class="nav-link"
                    >
                        <router-link :to="{ name: 'about-us' }">
                            About us
                        </router-link>
                    </li>
                    <li
                        v-intro-position="'right'"
                        v-intro="introTexts.founders"
                        v-intro-tooltip-class="`sidebar`"
                        class="nav-link"
                    >
                        <router-link :to="{ name: 'founders-landing' }">
                            Become a Creator
                        </router-link>
                    </li>
                </ul>
            </div>
            <footer class="side-bar-footer">
                <ul>
                    <li>
                        <router-link :to="{ name: 'memod-end-user-license-agreement' }">
                            End-User License Agreement
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'memod-tos' }">
                            Terms of Service
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'memod-privacy-policy' }">
                            Privacy Policy
                        </router-link>
                    </li>
                </ul>
                <div
                    v-intro-position="'right'"
                    v-intro="introTexts.socials"
                    v-intro-tooltip-class="`sidebar`"
                    class="social-media"
                >
                    <a href="https://www.facebook.com/memodapp" target="_blank" class="network">
                        <img src="@assets/img/icons/social/facebook.svg" alt="Facebook">
                    </a>
                    <a href="http://instagram.com/memodapp" target="_blank" class="network">
                        <img src="@assets/img/icons/social/instagram.svg" alt="instagram">
                    </a>
                    <a href="https://twitter.com/memodapp" target="_blank" class="network">
                        <img src="@assets/img/icons/social/twitter.svg" alt="twitter">
                    </a>
                    <a href="https://www.linkedin.com/company/memo'd/" target="_blank" class="network">
                        <img src="@assets/img/icons/social/linkedin.svg" alt="linkedin">
                    </a>
                    <a href="https://www.youtube.com/channel/UCvCXWvi3yJQTSxAUJjoehBQ" target="_blank" class="network">
                        <img src="@assets/img/icons/social/youtube.svg" alt="youtube">
                    </a>
                </div>
                <p class="copyright">
                    &copy; 2020 Memo'd Technologies, LLC.<br>All rights reserved.
                </p>
            </footer>
        </div>
        <transition name="fade" mode="out-in">
            <div v-if="showSideBar" class="overlay" @click="toggleSideBar(false)" />
        </transition>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "SideBar",
    props: {
        isValidInvitation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            introTexts: {
                search: "<h1>Find Memos</h1><p>Search for topics, creators, books, authors or any subject you're interested in.</p>",
                explore: "<h1>Explore</h1><p>Browse a curated feed of Memos and creators we think you'll love.</p>",
                about: "<h1>About</h1><p>Learn more about Memo'd - the platform, the people, and how it can improve your life.</p>",
                founders: "<h1>Become a Creator</h1><p>Join our Founding Creator's Program to turn your knowledge into revenue on Memo'd.</p>",
                socials: "<h1>Let's Get Social</h1><p>Connect with the Memo'd knowledge-sharing community on Facebook, Instagram and Twitter.</p>"
            }
        }
    },
    computed: {
        ...mapState({
            showSideBar: state => state.Application.showSideBar,
            showSideBarTour: state => state.Application.showSideBarTour
        })
    },
    methods: {
        toggleSideBar(value) {
            this.$store.dispatch("Application/toggleSideBar", value);
        },
        search(searchString) {
            if (searchString != "" && (searchString != this.$route.params.searchString)) {
                if (this.$route.name.includes("web-app-search")) {
                    this.$router.push({ name: this.$route.name, params: { searchString } });
                } else {
                    this.$router.push({ name: "web-app-search", params: { searchString } });
                }
            }
        },
        setTourEnded() {
            localStorage.setItem("alreadyShowedSidebarTour", true);
            this.$store.dispatch("Application/toggleSideBar", false);
            this.$store.dispatch("Application/toggleSideBarTour", false);

        }
    }
}
</script>

<style lang="scss" scoped>
.side-bar-components {
    position: relative;
    z-index: 10;
    position: fixed;
    top: 0;

    .side-bar-toggle {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: #2C2C2E;
        cursor: pointer;
        transition: background-color .3s;
        display: flex;
        align-items: center;
        justify-content: center;

        &.show-button {
            position: absolute;
            left: 15px;
            top: 15px;

            &:hover {
                background-color: lighten(#2C2C2E, 10%);
            }
        }
    }

    .side-bar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .search-input {
            position: relative;
            z-index: 3;

            .search-icon {
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
            }

            input {
                @extend .body-text;
                height: 50px;
                width: 205px;
                border-radius: 30px;
                background-color: #2C2C2E;
                box-shadow: none;
                color: white;
                border: 1px solid #38383A;
                padding-left: 45px;
                padding-right: 15px;
                transition: width .3s;

                &:focus {
                    outline: 0;
                }
            }
        }
    }

    .side-bar {
        position: fixed;
        top: 0;
        height: 100vh;
        width: 310px;
        background-color: #1C1C1E;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 15px 20px;
        z-index: 2;
        left: -310px;
        transition: left .3s;

        .nav-links {
            padding-left: 0;

            .nav-link {
                padding: 12px 0;

                a {
                    display: block;
                    text-decoration: none;
                    font-size: 18px;
                    color: white;

                    &.router-link-exact-active {
                        color: $primary-color;
                    }
                }
            }
        }

        .side-bar-footer {
            ul {
                padding-left: 0;
                list-style: none;

                li {
                    padding: 12px 0;

                    a {
                        font-size: 14px;
                        color: rgba(235, 235, 245, 0.6);
                    }
                }
            }

            .social-media {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                .network {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 20px;
                    opacity: .2;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            .copyright {
                color: #3A3A3C;
                margin-bottom: 0;
            }
        }

        &.show-side-bar {
            left: 0;
        }
    }

    .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
    }

}
.introjs-fixParent {
    z-index: 10 !important;
}
</style>
